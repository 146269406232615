/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

//  HanyeLeo Pages
import Home from "hanyeleo/home";
import EventDetails from "hanyeleo/events/EventDetails";
import EventImage from "hanyeleo/events/EventImage";
import PromoterEvents from "hanyeleo/promoters/events";
import PromoterNewEvent from "hanyeleo/promoters/new/NewEvent";
import PromoterSingleEventLayout from "hanyeleo/promoters/eventlayout";
import PromoterEventSettingsLayout from "hanyeleo/promoters/gallery";
import History from "hanyeleo/events/history";

import NewEvent from "pages/Dashboard/Promoter/Events/New/NewEvent";
import AdminNewEvent from "hanyeleo/Admin/Events/New/NewEvent";

// Admin Pages
import SingleEventLayoutAdmin from "hanyeleo/Admin/Events/SingleEvent/SingleEventLayout";
import AdminEvents from "hanyeleo/Admin";
import AdminTopEvents from "hanyeleo/Admin/Events/TopThreeEventsLayout";

import { Navigate, useLocation } from "react-router-dom";



// Account
import SignInCoverPage from "hanyeleo/login";
import SignUpCoverPage from "hanyeleo/signup";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";

// import promoter signup
import PromoterSignup from "hanyeleo/promoterSignup";


const userInfo = JSON.parse(localStorage.getItem("userInfo"));

// alert(userInfo.access);

const basicRoutes = [
  {
    name: null,
    route: "/",
    component: <Home />,
  },
  {
    name: null,
    route: "/home",
    component: <Home />,
  },
  {
    name: null,
    route: "/event/:eventId",
    component: <EventDetails />,
  },
  {
    route: "/event-image/:imgeRef/:eventId/:imageId",
    component: <EventImage />,
  },
  {
    name: null,
    route: "/sign-in/",
    component: <SignInCoverPage />,
  },
  {
    name: null,
    route: "/sign-up/",
    component: <SignUpCoverPage />,
  },
  {
    name: null,
    route: "/authentication/reset-password/cover",
    component: <ResetPasswordPage />,
  },
  {
    icon: <Icon>dashboard</Icon>,
    name: "Current events",
    route: "/home",
    component: <Home />,
  },
  {
    icon: <Icon>dashboard</Icon>,
    name: "Past events",
    route: "/pages/histories",
    component: <History />,
  },
  {
    icon: <Icon>dashboard</Icon>,
    // name: "Promoter Signup",
    route: "/promoter-signup",
    component: <PromoterSignup />,
  },
];

// const allRoutes = [
//   {
//     name: null,
//     route: "/",
//     component: <Home />,
//   },
//   {
//     name: null,
//     route: "/home",
//     component: <Home />,
//   },
//   {
//     name: null,
//     route: "/event/:eventId",
//     component: <EventDetails />,
//   },
//   {
//     route: "/event-image/:imgeRef/:eventId/:imageId",
//     component: <EventImage />,
//   },
//   {
//     route: "/pages/events/new",
//     component: <NewEvent />,
//   },
//   {
//     route: "/promoter/events/single/:id",
//     component: <PromoterSingleEventLayout />,
//   },
//   {
//     route: "/admin/events/new",
//     component: <AdminNewEvent />,
//   },
//   {
//     route: "/admin/events/single/:id",
//     component: <SingleEventLayoutAdmin />,
//   },
//   {
//     route: "/admin/events/top-events",
//     component: <AdminTopEvents />,
//   },
//   {
//     route: "/admin/events",
//     component: <AdminEvents />,
//   },
//   {
//     route: "/pages/events/update/:id",
//     component: <NewEvent />,
//   },
//   {
//     name: null,
//     route: "/promoter/events/settings/:id",
//     component: <PromoterEventSettingsLayout />,
//   },
//   {
//     name: null,
//     route: "/promoter/events",
//     component: <PromoterEvents />,
//   },
//   {
//     name: null,
//     route: "/promoter/events/new",
//     component: <PromoterNewEvent />,
//   },
//   {
//     name: null,
//     route: "/promoter/events/update/:id",
//     component: <PromoterNewEvent />,
//   },
//   {
//     name: null,
//     route: "/sign-in/",
//     component: <SignInCoverPage />,
//   },
//   {
//     name: null,
//     route: "/sign-up/",
//     component: <SignUpCoverPage />,
//   },
//   {
//     name: null,
//     route: "/authentication/reset-password/cover",
//     component: <ResetPasswordPage />,
//   },
//   {
//     route: "/pages/events/new",
//     component: <NewEvent />,
//   },
//   {
//     icon: <Icon>dashboard</Icon>,
//     name: "Current events",
//     route: "/home",
//     component: <Home />,
//   },
//   {
//     icon: <Icon>dashboard</Icon>,
//     name: "Past events",
//     route: "/pages/histories",
//     component: <History />,
//   },
  
// ];

const promoterRoutes = [
  {
    route: "/pages/events/new",
    component: <NewEvent />,
  },
  {
    route: "/promoter/events/single/:id",
    component: <PromoterSingleEventLayout />,
  },
  {
    route: "/pages/events/update/:id",
    component: <NewEvent />,
  },
  {
    name: null,
    route: "/promoter/events/settings/:id",
    component: <PromoterEventSettingsLayout />,
  },
  {
    name: null,
    route: "/promoter/events",
    component: <PromoterEvents />,
  },
  {
    name: null,
    route: "/promoter/events/new",
    component: <PromoterNewEvent />,
  },
  {
    name: null,
    route: "/promoter/events/update/:id",
    component: <PromoterNewEvent />,
  },
  
];

const adminRoutes = [
  {
    route: "/admin/events/new",
    component: <AdminNewEvent />,
  },
  {
    name: null,
    route: "/admin/events/update/:id",
    component: <AdminNewEvent />,
  },
  {
    route: "/admin/events/single/:id",
    component: <SingleEventLayoutAdmin />,
  },
  {
    route: "/admin/events/top-events",
    component: <AdminTopEvents />,
  },
  {
    route: "/admin/events",
    component: <AdminEvents />,
  },
];

const wildcardRoute = [
  {
    route: "*",
    component: <Navigate to="/" replace />,
  },
];

var routes = [];

if (userInfo == null || userInfo.access < 2)
  routes =  [...basicRoutes, ...wildcardRoute];
else if (userInfo.access < 5) // Promoter
  routes = [...basicRoutes, ...promoterRoutes, ...wildcardRoute];
else if (userInfo.access == 5) // Admin
routes = [...basicRoutes, ...promoterRoutes, ...adminRoutes, ...wildcardRoute];
else
routes =  [...basicRoutes, ...wildcardRoute];

export default routes;
