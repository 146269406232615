import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultNavbar from "hanyeleo/Navbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Container from "@mui/material/Container";
import navRoutes from "navroutes";
import footerRoutes from "footer.routes";
import product5 from "assets/images/HanyeIcon.png";
import Axios from "utils/Axios";
import { useDispatch } from "react-redux";
import { setRedirectionUrl } from "../redux/slices/redirection/redirectionSlice";

function Cover() {
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    phone: "",
    promoterName: "",
    city: "",
    promoterType: "",
    aboutYourself: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  // New state for API-level errors and success message
  const [apiError, setApiError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const phoneRegex = /^[0-9]{10}$/;

  const validateForm = () => {
    const newErrors = {};

    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    if (!formData.promoterName) newErrors.promoterName = "Promoter name is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.promoterType) newErrors.promoterType = "Promoter type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error for specific field on input change
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));

    // Clear any API-level errors when user starts typing
    setApiError("");
    setSuccessMessage("");
  };
  const resetForm = () => {
    setFormData({
      phone: "",
      promoterName: "",
      city: "",
      promoterType: "",
      aboutYourself: "",
    });
  };

  const handleRegistration = (e) => {
    e.preventDefault();

    // Clear previous messages
    setApiError("");
    setSuccessMessage("");

    if (!validateForm()) return;

    // get user id from localstorage userInfo
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setLoading(true);
    const data = JSON.stringify({
      userId: userInfo.uid,
      type: "promoters",
      ...formData,
    });

    Axios.post("", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setSuccessMessage("Your request submitted successfully!");

        // Optional: Navigate after a short delay to show success message
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        // Detailed error handling
        const errorMessage =
          err.response?.data?.message ||
          err.response?.data?.error ||
          "An unexpected error occurred. Please try again.";
        setApiError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRedirection = (path) => {
    dispatch(setRedirectionUrl("/promoter-signup"));
    navigate(path);
  };
  const promoterTypes = ["Promoter", "Venue Owner/Manager", "Marketing Agency"];

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true); // User is logged in
    }
    dispatch(setRedirectionUrl(""));
    const fetchCities = () => {
      Axios.get("?type=cities")
        .then((response) => {
          setCities(response.data); // Set cities data
        })
        .catch((error) => {
          console.error("Failed to fetch cities", error);
          setApiError("Failed to load cities. Please refresh the page.");
        });
    };
    fetchCities();
  }, []);
  return (
    <>
      <DefaultNavbar routes={navRoutes} relative center />

      <section className="bg-gray-100">
        <Container>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between py-12 md:space-x-16 px-6">
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-2xl md:text-4xl font-bold text-black mb-4">
                Join us today on <span className="text-purple-800">HanyeLeo</span>
              </h1>
              <p className="text-lg text-gray-700 mb-6">To join us please follow below steps</p>
              <br></br>
              {/* Ordered list of requirements */}
              <ol className="list-decimal pl-6 text-base text-gray-700 mb-6">
                {!isLoggedIn ? (
                  <li>
                    Create a regular user account -{" "}
                    <a
                      onClick={() => handleRedirection("/sign-up")}
                      className="text-purple-600 hover:text-purple-800 underline cursor-pointer"
                    >
                      signup
                    </a>{" "}
                    or{" "}
                    <a
                      onClick={() => handleRedirection("/sign-in")}
                      className="text-purple-600 hover:text-purple-800 underline cursor-pointer"
                    >
                      Login
                    </a>{" "}
                    if you have account
                  </li>
                ) : (
                  <li className="flex items-center">
                    Create a regular user account
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 font-thin border border-green-300 rounded-full text-xs bg-green-200 text-green-800">
                      Done
                    </span>
                  </li>
                )}
                <li>Submit Form below with your account info</li>
                <li>We will contact you to setup your promoter account </li>
              </ol>
            </div>
            <div className="w-full md:w-1/6 flex items-center md:justify-end">
              <img src={product5} alt="Landing Image" />
            </div>
          </div>
        </Container>
      </section>

      <Container sx={{ mt: 5 }} className="pb-2">
        <div className=" flex justify-center">
          <div className="md:w-2/3">
            {/* Error Message */}
            {apiError && (
              <div
                className="bg-red-50 border border-red-400 text-red-500 px-4 py-2 mx-5 rounded-lg text-sm relative mb-4"
                role="alert"
              >
                <span className="block sm:inline">{apiError}</span>
              </div>
            )}

            {/* Success Message */}
            {successMessage && (
              <div
                className="bg-green-50 border border-green-400 text-green-600 px-4 py-3 mx-5 rounded-lg text-sm relative mb-4"
                role="alert"
              >
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {!isLoggedIn && (
              <div className="bg-yellow-50 border border-yellow-400 text-yellow-600 px-4 py-3 mx-5 rounded-lg text-sm relative mb-4">
                <span className="text-xs italic px-2">
                  To enable bellow form -{" "}
                  <a
                    onClick={() => handleRedirection("/sign-up")}
                    className="text-purple-600 hover:text-purple-800 underline cursor-pointer"
                  >
                    signup
                  </a>{" "}
                  or{" "}
                  <a
                    onClick={() => handleRedirection("/sign-in")}
                    className="text-purple-600 hover:text-purple-800 underline cursor-pointer"
                  >
                    Login
                  </a>{" "}
                  if you have account
                </span>
              </div>
            )}
            <MKBox p={3}>
              <MKBox component="form" role="form" onSubmit={handleRegistration}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MKInput
                      type="tel"
                      variant="outlined"
                      label="Phone Number"
                      value={formData.phone}
                      onChange={(e) => handleInputChange("phone", e.target.value)}
                      fullWidth
                      disabled={!isLoggedIn}
                      error={!!errors.phone}
                      helperText={errors.phone}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MKInput
                      type="text"
                      variant="outlined"
                      label="Promoter Name (displayed on your Events)"
                      value={formData.promoterName}
                      onChange={(e) => handleInputChange("promoterName", e.target.value)}
                      fullWidth
                      disabled={!isLoggedIn}
                      error={!!errors.promoterName}
                      helperText={errors.promoterName}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      select
                      label=""
                      value={formData.city}
                      onChange={(e) => handleInputChange("city", e.target.value)}
                      fullWidth
                      disabled={!isLoggedIn}
                      error={!!errors.city}
                      helperText={errors.city}
                      SelectProps={{ native: true }}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    >
                      <option value="" disabled>
                        Select a city
                      </option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.title}
                        </option>
                      ))}
                    </MKInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MKInput
                      select
                      label=""
                      value={formData.promoterType}
                      onChange={(e) => handleInputChange("promoterType", e.target.value)}
                      fullWidth
                      disabled={!isLoggedIn}
                      error={!!errors.promoterType}
                      helperText={errors.promoterType}
                      SelectProps={{ native: true }}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    >
                      <option value="" disabled>
                        Select a Promoter type
                      </option>
                      {promoterTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </MKInput>
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      type="text"
                      variant="outlined"
                      label="Tell us about yourself"
                      value={formData.aboutYourself}
                      onChange={(e) => handleInputChange("aboutYourself", e.target.value)}
                      multiline
                      rows={3}
                      fullWidth
                      disabled={!isLoggedIn}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      fullWidth
                      disabled={loading || !isLoggedIn}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </div>
        </div>
      </Container>
      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default Cover;
