import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    url: "",
};

const redirectionSlice = createSlice({
    name: "redirection",
    initialState,
    reducers: {
        setRedirectionUrl: (state, action) => {
            state.url = action.payload;
        },
    },
});

export const { setRedirectionUrl } = redirectionSlice.actions;
export default redirectionSlice.reducer;