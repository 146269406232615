import { createSlice } from "@reduxjs/toolkit";

// declaring initial values

const initialState = {
  userData: {
    userName: "N/A",
    userEmail: "N/A",
  },
  redirection: ""
};

// creating our reducers

const userInfoSlice = createSlice({
  name: "userInformation",
  initialState,
  reducers: {
    // populating user info into a reducer, update userrname and email

    setUserData(state, action) {
      state.userData.userName = action.payload.name;
      state.userData.userEmail = action.payload.email;
    },
    updateUserEmail(state, action) {
      state.userData.userEmail = action.payload.name;
    },
    updateuserName(state, action) {
      state.userData.userName = action.payload.email;
    },
    clearUserInfo(state) {
      state.userData.userEmail = "N/A";
      state.userData.userName = "N/A";
    },
    setRedirection(state, action) {
      state.redirection = action.payload;
    }
  },
});

export const { setUserData, updateUserEmail, updateuserName, setRedirection } = userInfoSlice.actions;
export default userInfoSlice.reducer;
