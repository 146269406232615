import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./slices/user/userSlice";
import authReducer from "./slices/auth/authSlice"
import cityReducer from "./slices/city/citySlice"
import redirectionReducer from "./slices/redirection/redirectionSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    userInfo: userInfoReducer,
    city: cityReducer,
    redirection: redirectionReducer
  },
});

export default store;
