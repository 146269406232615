import React, { useState } from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import Icon from "@mui/material/Icon";
import "./index.scss"; // Import your existing styles
import { sidebarData } from "./sideBarData";
import listItem from "assets/theme/components/list/listItem";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location (URL)

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const tokenInfo = localStorage.getItem("token");
  const accessLevel = userInfo?.access;

  // State to track expanded sections
  const [expandedSection, setExpandedSection] = useState(null);

  const handleToggle = (id) => {
    setExpandedSection(expandedSection === id ? null : id);
  };

  const modifiedSidebarData = sidebarData.map((section) => {
    return {
      ...section,
      listItems: section.listItems.map((listItem) => {
        return listItem;
      }),
    };
  });

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="menu">
      {modifiedSidebarData.map((item) => (
        <div className="item" key={item.id}>
          <span className="title">{item.title}</span>
          {item.listItems.map((listItem) => {
            if (listItem.title === "Logout") {
              return (
                <button
                  onClick={handleLogout}
                  className="listItem logoutButton"
                  key={listItem.id}
                >
                  <Icon color="inherit">{listItem.icon}</Icon>
                  <span className="listItemTitle">{listItem.title}</span>
                </button>
              );
            } else if (listItem.children) {
              // Only expand the section if it's toggled
              const isExpanded = expandedSection === listItem.id;

              return (
                <div key={listItem.id}>
                  <div
                    className="listItem" // No 'active' class for the parent menu when a submenu is active
                    onClick={() => handleToggle(listItem.id)}
                  >
                    <Icon color="inherit">{listItem.icon}</Icon>
                    <span className="listItemTitle">{listItem.title}</span>
                  </div>
                  {isExpanded &&
                    listItem.children.map((child) => (
                      <Link
                        to={child.url}
                        className={`listItem subItem ${location.pathname === child.url ? "active" : ""}`} // Only sub-items get active class
                        key={child.id}
                      >
                        <Icon color="inherit">{child.icon}</Icon>
                        <span className="listItemTitle">{child.title}</span>
                      </Link>
                    ))}
                </div>
              );
            } else {
              return (
                <Link
                  to={listItem.url}
                  className={`listItem ${location.pathname === listItem.url ? "active" : ""}`}
                  key={listItem.id}
                >
                  <Icon color="inherit">{listItem.icon}</Icon>
                  <span className="listItemTitle">{listItem.title}</span>
                </Link>
              );
            }
          })}
        </div>
      ))}
    </div>
  );
}

export default Sidebar;