import React, { useEffect, useState } from "react";
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { useDispatch } from 'react-redux';
import { setRedirectionUrl } from "../../../redux/slices/redirection/redirectionSlice";

function Testimonials() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
   const dispatch = useDispatch();
   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //set redirection state using dispatch
  const setRedirect = () => {
    dispatch(setRedirectionUrl("/promoter-signup"))
    if(isLoggedIn) {
      if (userInfo.access === "5") {
        navigate("/admin/events/new");
      } else {        
        navigate(userInfo.promoter ? "/promoter/events/" : "/promoter-signup");
      }
    } else {
      navigate("/promoter-signup");
    }
  };
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true); // User is logged in
    }
  }, [])
  return (
    <MKBox position="relative" variant="gradient" bgColor="light" mt={6} py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" color="dark" mb={1}>
              Add Your Own Events On Hanye Leo
            </MKTypography>
            <MKTypography variant="body1" color="dark" fontWeight="light">
              Are you a Promoter or Manage a Venue? Sign-up here to add your events:
            </MKTypography>
            <br></br>
            <button
              onClick={setRedirect}
              type="button"
              className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm px-16 py-3 me-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
            >
              {isLoggedIn ? 'Add your Events' : 'Sign up to Add your Events'}
            </button>

            {/* <MKButton  variant="gradient" color="info" className="mt-10">
            Sign up to Add your Events
          </MKButton> */}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
