import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useParams } from "react-router-dom";
import Axios from "utils/Axios";
import { useNavigate } from "react-router-dom";
//import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import MKBox from "components/MKBox";
import Avatar from "@mui/material/Avatar";
import coverImageDefault from "../../../../assets/images/groove-sign-up.jpg";
//import { Button, LinearProgress  } from '@material-ui/core';
import { Button, LinearProgress } from "@mui/material";

import "./singleEvent.scss";

function SingleEvent() {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const [showDescription, setShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const [rows, setRows] = useState(null);

  const imageUrl = (url) => {
    return process.env.REACT_APP_BASE_IMAGE_URL + url;
  };
  const navigate = useNavigate();
  const gotoEditEvent = (eventId) => {
    navigate(`/admin/events/update/${eventId}`);
  };

  // approve events
  const activateEvent = (isActive) => {
    // start loading bar
    setLoading(true);
    //--- constructing the payload ---
    const data = JSON.stringify({
      type: "adminEvent",
      eventId: id,
      isActive: isActive,
    });
    console.log(id, "EVENT ID AMIGO");
    console.log(isActive, "IS ACTIVE");
    Axios.post("/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response.data, "Event Approved");
        if (response.data.message === "Event Approved") {
          navigate("/admin/events/");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      })
      .finally(() => {
        //stop loading
        setLoading(false);
      });
  };
  // const gotoEventSettings = (eventId) => {
  //   navigate(`/pages/events/settings/${eventId}`);
  // };
  const gotoEventGallery = (eventId) => {
    navigate(`/promoter/events/settings/${eventId}`);
  };
  const getSigleEvent = () => {
    Axios.get(`?type=event&id=${id}`)
      .then((response) => {
        //console.log(response.data);
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };
  useEffect(() => {
    getSigleEvent();
  }, []);
  return (
    <>
      <div className="singleEvent grid grid-cols-1 lg:grid-cols-2 gap-x-4">
        <div className="view">
          <div className="info flex flex-col md:flex-row gap-4">
            <div>
              <Avatar
                alt={rows?.title}
                src={imageUrl(rows?.image) || coverImageDefault}
                sx={{ width: 200, height: 200 }}
                variant="rounded"
              />
            </div>
            <div className="details relative">
              <div className="item">
                {/* <span className="itemTitle">Event Name: </span> */}
                <span className="itemValue text-2xl font-semibold">
                  {rows?.title} / {rows?.date}
                </span>
              </div>
              <div className="item">
                {/* <span className="itemTitle">Event Date: </span> */}
                {/* <span className="itemValue">{rows?.date}</span> */}
              </div>
              <div className="item">
                {/* <span className="itemTitle">Event Venue: </span> */}
                <span className="itemValue text-sm text-gray-300 font-thin">{rows?.location}</span>
              </div>
              <div className="flex gap-4 xl:absolute xl: bottom-0">
                <div>
                  {loading ? (
                    <span>loading....</span>
                  ) : //<LinearProgress color="secondary"/>  // Show LinearProgress when loading is true
                  rows && rows.is_active !== undefined ? ( // Check if rows and is_active are defined
                    rows.is_active === "0" ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          activateEvent(1);
                        }}
                      >
                        Approve Event
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          activateEvent(0);
                        }}
                      >
                        Deactivate Event
                      </Button>
                    )
                  ) : (
                    <span>Loading...</span> // If rows are not available, show loading text
                  )}
                </div>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    gotoEditEvent(rows?.id);
                  }}
                >
                  Edit Event
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    gotoEventGallery(id);
                  }}
                >
                  Gallery
                </Button>
                {/* <button
                  onClick={() => {
                    setShowDescription(!showDescription);
                  }}
                  type="button"
                  className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                >
                  {showDescription ? "Close preview" : "Priview Description"}
                </button> */}
              </div>
            </div>
          </div>
          <hr />
          <div>
            <h2 className="text-gray-100 font-semibold mb-5">Description Details</h2>
            <MKBox
              sx={{
                "& p": {
                  marginBottom: 2,
                },
                "& img": {
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: 2,
                },
                "& h2, & h3, & h4, & h5, & h6": {
                  marginTop: 3,
                  marginBottom: 1,
                },
                "& ul, & ol": {
                  marginLeft: 4,
                  marginBottom: 2,
                },
              }}
              color={"light"}
              className="font-light pr-5"
              dangerouslySetInnerHTML={{ __html: rows?.description }}
            />
          </div>
          <div>
            {/* <button
                        onClick={() => {
                          gotoEventSettings(id);
                        }}
                        type="button"
                        variant="outline"
                        className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                      >
                        Event Gallery
                      </button> */}
          </div>
          {/* <div className="chart">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div> */}
        </div>
        <div className="mt-10 md:mt-0">
          {/* <div className="activities">
            <h2>Latest Activities</h2>
            <ul>
              <li>
                <div>
                  <p>Master of Ceremony in the building</p>
                  <time>3 min ago</time>
                </div>
              </li>
              <li>
                <div>
                  <p>DJ and live band commencement</p>
                  <time>20 min ago</time>
                </div>
              </li>
              <li>
                <div>
                  <p>Umuhanzi yahasesekaye</p>
                  <time>53 min ago</time>
                </div>
              </li>
              <li>
                <div>
                  <p>Dancers on the floor</p>
                  <time>1 hour ago</time>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SingleEvent;
