
const tokenInfo = localStorage.getItem("token");
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const accessLevel = userInfo?.access;

const sidebarData1 = [
  {
    id: 1,
    title: "Main",
    listItems: [
      {
        id: 1,
        title: "Home",
        url: "/",
        icon: "home",
      },
    ],
  },
];

if (accessLevel === "2" && tokenInfo) {
  sidebarData1.push({
    id: 2,
    title: "Promoter",
    listItems: [
      {
        id: 2,
        title: "Events",
        url: "/promoter/events/",
        icon: "event",
      },
    ],
  });
}
else if (accessLevel === "5" && tokenInfo) {
  sidebarData1.push({
    id: 2,
    title: "Admin",
    listItems: [
      {
        id: 1,
        title: "New Event", 
        url: "/admin/events/new",
        icon: "library_add",
      },
      {
        id: 2,
        title: "Events",
        url: "/admin/events",
        icon: "event",
      },
      {
        id: 3,
        title: "Top Events",
        url: "/admin/events/top-events",
        icon: "star",
      },
    ],
  });
}

export const sidebarData = sidebarData1;
