import { useState } from "react";
import Axios from "utils/Axios";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slices/auth/authSlice";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CoverLayout from "./CoverLayout";
import bgImage from "assets/images/city-profile.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import { GoogleLogin } from "@react-oauth/google";

function SignInCover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasInput, setHasInput] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [googleError, setGoogleError] = useState("");
  const [apiError, setApiError] = useState("");

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Your email address is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!password) newErrors.password = "Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const data = JSON.stringify({
      type: "authenticate",
      email: email,
      password: password,
    });

    setLoading(true);

    try {
      let res = await Axios.post("/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        const data = res.data;
        const userEmail = data.user["email"];
        const userName = data.user["name"];
        const access = data.user["access"];

        dispatch(loginSuccess({ userEmail, userName }));
        localStorage.setItem("token", data.jwtToken);
        localStorage.setItem("userInfo", JSON.stringify(data.user));

        if (access === "2") {
          navigate("/promoter/events/");
        } else if (access === "5") {
          navigate("/admin/events/");
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.response?.data?.error ||
        "An unexpected error occurred. Please try again.";
      setApiError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Handle input for password field
  const handlePasswordInput = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setHasInput(inputValue.length > 0);
  };

    // Google Login Handlers
    const handleGoogleLoginSuccess = async (credentialResponse) => {
      const { credential } = credentialResponse;
  
      // Optionally, display a loading indicator
      setLoading(true);
  
      // Send the credential (JWT) to your backend for verification
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/google-login/`, { token: credential })
        .then((res) => {
          if (res.status === 200 && res.data.message === "authenticated") {
            // Redirect or perform any action upon successful authentication
            // navigate("/dashboard");
  
            const data = res.data;
            const userEmail = data.user["email"];
            const userName = data.user["name"];
            const access = data.user["access"];
  
            // alert("Authenticated");
            // alert(JSON.stringify(data));
  
            // dispatch(loginSuccess({ userEmail, userName }));
            localStorage.setItem("token", data.jwtToken);
            localStorage.setItem("userInfo", JSON.stringify(data.user));
  
            if (access === "2") {
              navigate("/promoter/events/");
            } else if (access === "5") {
              navigate("/admin/events/");
            } else {
              navigate("/");
            }
  
          } else {
            console.log("Authentication failed");
          }
        })
        .catch((err) => {
          
          console.error(err);
          setGoogleError("Authentication failed. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleGoogleLoginError = () => {
      console.log("Google Login Failed");
      setGoogleError("Google Login Failed. Please try again.");
    };

  const viewPassword = () => {
    setPasswordView(!passwordView);
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login to HanyeLeo
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to login
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          {apiError && (
            <div
              className="bg-red-50 border border-red-400 text-red-500 px-4 py-2 mx-0 rounded-lg text-sm relative mb-4"
              role="alert"
            >
              <span className="block sm:inline">{apiError}</span>
            </div>
          )}
          <MKBox component="form" role="form" onSubmit={handleLogin}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                variant="standard"
                label="Email"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                }}
                error={!!errors.email}
                helperText={errors.email}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView ? "text" : "password"}
                variant="standard"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {hasInput && (
                        <Icon sx={{ cursor: "pointer" }} onClick={viewPassword} color="inherit">
                          {passwordView ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                onChange={(e) => {
                  handlePasswordInput(e);
                  setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
                }}
                error={!!errors.password}
                helperText={errors.password}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton
                type="submit"
                variant="gradient"
                color="dark"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
              </MKButton>
            </MKBox>

            {/* Google Sign-Up Option */}
            <MKBox mt={2} mb={1} textAlign="center">
              <MKTypography variant="body1" color="text">
                Or sign up with
              </MKTypography>
            </MKBox>
            <MKBox display="flex" justifyContent="center" mt={1} mb={2}>
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
              />
            </MKBox>
            {googleError && (
              <MKBox mb={2} textAlign="center">
                <MKTypography variant="caption" color="error">
                  {googleError}
                </MKTypography>
              </MKBox>
            )}

            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                You don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/sign-up/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default SignInCover;
